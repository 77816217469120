import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { GtrForm } from '@/interfaces/common.interface'

@Component({
  name: 'GtrRegistrationModuleOptionsView',
  computed: {
    ...mapState('option', ['option_groups']),
    ...mapState('formbuilder', ['form'])
  }
})
export default class GtrRegistrationModuleOptionsView extends Vue {
  option_groups!: Array<any>
  form!: GtrForm;

  data () {
    return {
      search: '',
      loading: false,
      submitting: false,
      optionGroupToDelete: null,
      duplicateDialog: false,
      optionRename: null,
      optionGroupToDuplicate: {},
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'On Form',
          align: 'center',
          value: 'uuid'
        },
        {
          text: 'Pricing',
          align: 'center',
          value: 'pricing'
        },
        { text: 'Actions', align: 'left', value: 'controls', sortable: false }
      ]
    }
  }

  async mounted () {
    await this.fetchOptions()
    await this.fetchForm()
  }

  /**
   * @getter allOptionGroupUuidsOnForm
   * @description This getter returns an array of all option group uuids that are currently on the form.
   * @returns {Array<string>}
   */
  get allOptionGroupUuidsOnForm () {
    const form = this.form as GtrForm
    if (Array.isArray(form?.page_data)) {
      return form.page_data
        .map((page) => page.fields)
        .flat()
        .map((field) => field.option_group_uuid)
        .filter((uuid) => uuid)
    }
    return []
  }

  get activeOptionGroups () {
    return this.option_groups
      .filter(group => !['SESSIONGRP', 'LROPTIONGRP'].includes(group.type))
      .map(({ name, options, uuid }) => ({
        uuid,
        name,
        pricing: options.some(option => (Array.isArray(option.pricing) && option.pricing.length > 0))
      }))
  }

  readOnlyField (group: any) {
    /**
     * If these groups are being edited, don't allow user to change their name
     */
    return group.name === 'Registration Types' || group.name === 'Additional User Line Items'
  }

  handleOptionGroupEdit (payload: any) {
    this.$router.push({ name: 'level-two.modules.registration.options.edit', params: { option_uuid: payload.uuid } })
  }

  async handleOptionGroupDuplicate (payload: any) {
    this.openDuplicateDialog()
    this.$data.optionGroupToDuplicate = payload
  }

  async submitOptionGroupDuplicate () {
    const optionGroupToDuplicate = this.$data.optionGroupToDuplicate
    const optionRename = this.$data.optionRename
    if (optionGroupToDuplicate.name === optionRename) {
      Container.get(Notification).error('Please enter a unique option group name.')
    } else {
      const data: any = {
        name: optionRename
      }
      try {
        await this.$store.dispatch('option/duplicateOptionGroup', { event_uuid: this.$route.params.event_uuid, option_group_uuid: optionGroupToDuplicate.uuid, data })
        Container.get(Notification).success('Option group successfully duplicated.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.closeDuplicateDialog()
      }
    }
  }

  openDuplicateDialog () {
    this.$data.duplicateDialog = true
  }

  closeDuplicateDialog () {
    this.$data.duplicateDialog = false
    this.$data.optionRename = null
  }

  handleOptionGroupDelete (payload: any) {
    this.$data.optionGroupToDelete = payload
  }

  async onDeleteOptionGroupAction (payload: any) {
    if (payload.confirm) {
      try {
        this.$data.loading = true
        const data: any = {
          event_uuid: this.$route.params.event_uuid,
          option_group_uuid: this.$data.optionGroupToDelete.uuid
        }

        if (this.allOptionGroupUuidsOnForm.includes(this.$data.optionGroupToDelete.uuid)) {
          throw new Error('This option group is currently being used on the registration form. Please remove it from the form before deleting it.')
        }
        await this.$store.dispatch('option/deleteOptionGroup', data)
        await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
        Container.get(Notification).success('Option group successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }
    this.$data.optionGroupToDelete = null
  }

  async fetchOptions () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async fetchForm () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('formbuilder/getForm', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
